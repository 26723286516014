import React, { useState, useEffect } from 'react';
import apiService from '../../services/apiService';
import utilities from '../../services/utilities';
import Coins from '../bakery/Coins';
import './UserFriend.css';
import InviteLevelConfirmationDialog from './InviteLevelConfirmationDialog';
import EarnMoreDialog from './EarnMoreDialog';
import toast, { Toaster } from 'react-hot-toast';
import Confetti from "react-confetti";

const UserFriend = ({ balance, IsDisplayWelcome }) => {
  const [friends, setFriends] = useState([]);
  const [UrlLink, setUrlLink] = useState('');
  const [curLevelPoint, setCurLevelPoint] = useState(500000);
  const [showLevelConfirmation, setshowLevelConfirmation] = useState(false);
  const [showLevelDetails, setShowLevelDetails] = useState(false);
  const [curInviteLevel, setCurInviteLevel] = useState(0);
  const [showFireWorks, setshowFireWorks] = useState(false);
  const { width, height } = window;
  const notifyToast = (type, msg) => {
    if (type == 'success') {
      toast.success(msg == null ? 'Updated Successfully...' : msg);
    }
    else if (type == 'error') {
      toast.error(msg == null ? 'Action Failed...' : msg);
    }
    else if (type == 'wait') {
      toast(msg == null ? 'Processing data...' : msg);
    }

  };

  const updateInviteLevel = async () => {
    const response = await apiService.post('/updateInviteLevel', { point: parseInt(curLevelPoint), level: curInviteLevel });
    console.log('updateInviteLevel', response);
 
  }

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      let telegrmaID = window.Telegram.WebApp.initDataUnsafe.user.id;
      setUrlLink(`https://t.me/atdemoapp_bot/taptowin?startapp=refid`);
      if (telegrmaID) {
        setUrlLink(`https://t.me/atdemoapp_bot/taptowin?startapp=refid${telegrmaID}`);
      }
    }
  }, []);
 
 
  useEffect(() => {    
    if (IsDisplayWelcome == true) {
      setshowFireWorks(true);
      setTimeout(() => {
        setshowFireWorks(false);
      }, 4000);
    }
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      const response = await apiService.get('/friend');
      const friendData = response.data.data || [];
      console.log(friendData);
      setFriends(Array.isArray(friendData) ? friendData : []);
    } catch (error) {
      console.error('Error fetching mining cards:', error);
    }
  };
 
  useEffect(() => {
    const checkInvites = async () => {
      try {
        const response = await apiService.get('/check-invites');
        const inviteData = response.data.data || [];

        if (inviteData.curInviteLevel == 0 && inviteData.inviteCount >= 5) {
          setCurLevelPoint(250000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        }
        else if (inviteData.curInviteLevel == 1 && inviteData.inviteCount >= 10) {
          setCurLevelPoint(500000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        }
        else if (inviteData.curInviteLevel == 2 && inviteData.inviteCount >= 25) {
          setCurLevelPoint(2500000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        }
        else if (inviteData.curInviteLevel == 3 && inviteData.inviteCount >= 50) {
          setCurLevelPoint(5000000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        }
        else if (inviteData.curInviteLevel == 4 && inviteData.inviteCount >= 100) {
          setCurLevelPoint(10000000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        }

        if (inviteData.inviteCount != friends.length) {
          fetchCards();
        }
        // setFriends(Array.isArray(inviteData) ? friendData : []);
      } catch (error) {
        console.error('Error fetchInvites:', error);
      }
    };
    console.log('checkInvites is called')
    checkInvites();
    const intervalId = setInterval(checkInvites, 10000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    const handlePopState = () => {
      if (showLevelDetails) {
        handleClose();
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [showLevelDetails]);




  const handleClaim = async () => {

    try {
      notifyToast('success', 'Claim Successfull...');
      updateInviteLevel();
    } catch (error) {
      console.error('Error fetching mining cards:', error);
    }

    setshowLevelConfirmation(false);
    setshowFireWorks(true);
    setTimeout(() => {
      setshowFireWorks(false);
    }, 4000);
  };



  const copyToClipboard = () => {
    navigator.clipboard.writeText(UrlLink).then(() => {
      console.log('url' + UrlLink);
    }).catch(err => {
      console.error('Error copying text: ', err);
    });
  };

  const handleLevelDetailsClick = async () => {
    setShowLevelDetails(true);
    window.history.pushState(null, null);
  };

  const handleClose = () => {
    setshowLevelConfirmation(false);
    setShowLevelDetails(false);
    window.history.back();
  };



  return (
    <div className="user-friend-container">
      {showFireWorks && (<Confetti width={width} height={height} gravity={.15} numberOfPieces={400} />)}

      <Toaster />
      <div className="user-friend-content">
        <Coins balance={balance} />
        <div className="image-container">
          <img src="images/urt_256.png" alt="buddy" className="friend-cute-character" />
        </div>
        <span className="countdown"> 74:34:35</span>
        <p className="reward-text">You and your buddies will get +25,000 tokens.</p>
        {/* <p className="bonus-text">1000</p> */}

        <h2 onClick={handleLevelDetailsClick} style={{ textAlign: "center", marginTop: '4px', color: 'rgb(255 210 77)' }}> Earn More!!</h2>

        <div className="friends-container">
          <h2 style={{ textAlign: "left" }}>{friends.length} {friends.length == 1 ? 'Buddy' : 'Buddies'}</h2>
          <ul className="friend-list">
            {friends.map((friend, index) => (
              <li key={index} className="friend-item">
                <div className="friend-avatar">{friend.first_name[0].toUpperCase()}</div>
                <span className="friend-name">{friend.first_name}</span>
                <span className="friend-tokens"><img src='images/coin.png' height={14} width={14} style={{ marginRight: '3px' }} />   {utilities.formatNumber(friend.balance || 0)}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="invite-container">
          <a className="invite-button" href={`https://t.me/share/url?url=` + encodeURIComponent(UrlLink)} >
            Invite a Buddy
          </a>
          <button className="copy-button" onClick={copyToClipboard}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z" fill="black" />
            </svg>
          </button>
        </div>

        {showLevelConfirmation && (
          <InviteLevelConfirmationDialog
            title={`LEVEL ${curInviteLevel}`}
            imageName={`Level_${curInviteLevel}`}
            description={`Collect your rewards`}
            points={curLevelPoint}
            onConfirm={handleClaim}
            onCancel={handleClose}
          />
        )}
        {showLevelDetails && (
          <EarnMoreDialog
            onConfirm={handleClose}
            onCancel={handleClose}
          />
        )}


      </div>
    </div>
  );
};

export default UserFriend;
