import React, { useState } from 'react';
import './StepNavigation.css'; // For CSS styles

const StepNavigation = ({ UrlLink, totalSteps, onNext, onFinish }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [claimButtonText, setclaimButtonText] = useState('Claim');

  const handleNext = () => {
    navigator.vibrate(200);
    if (currentStep < totalSteps) {    
      setCurrentStep(currentStep + 1);
      onNext(currentStep);
    }
  };

  const handleClaimClick = () => {
    navigator.vibrate(200); 
    if (claimButtonText == 'Claim') {
      setclaimButtonText('Finish');
      const url = `https://t.me/share/url?url=` + encodeURIComponent(UrlLink);
     window.location.href = url; 
    }
    else {
      onFinish(false);
    }
  };

  const handleSkipClick = () => {
    navigator.vibrate(200);
    onFinish(true); 
  };

  return (
    <div className="step-navigation">
      <span style={currentStep % 2 === 0 ? { color: 'black' } : { color: 'white' }} className="step-counter">  {currentStep} of {totalSteps}</span>
      {currentStep == 4 ?
        <>

          <a
            style={currentStep % 2 === 0 ? { color: 'black' } : { color: 'white' }}
            className="next-link"
            onClick={handleClaimClick} >
            {claimButtonText}
          </a>
          <a
            style={currentStep % 2 === 0 ? { color: 'black' } : { color: 'white' }}
            className="next-link"
            onClick={handleSkipClick}
          >
            Skip
          </a>

        </>
        :
        <a
          style={currentStep % 2 === 0 ? { color: 'black' } : { color: 'white' }}
          href="#"
          className="next-link"
          onClick={handleNext}
          aria-disabled={currentStep === totalSteps}
        >
          Next
        </a>
      }



    </div>
  );
};

export default StepNavigation;
